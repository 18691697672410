import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Breadcrumb from '../components/breadcrumb'
import SectorPage from '../components/sectorPage'

const TelecomPage = ({location}) => {
  return (
    <Layout simpleHeader={true} url="/telecom" type="sector">
      <SEO 
        title="Classement des meilleurs sites téléphonie/internet"
        description="Quels sont les meilleurs sites de télécom de l'Observatoire de l'impact positif ? Analyse des opérateurs télécom et Internet français free, SFR, Bouygues et Orange."
        path="/telecom" />
      
      <Breadcrumb url="/telecom" label="Telecom" />

      <SectorPage slug="telecom" type="sector" />
    </Layout>
  )
  }

export default TelecomPage

